import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

@Pipe({
  name: 'carrierImg'
})
export class CarrierImgPipe implements PipeTransform {

  transform(company: string, type:string, partners:any): string {
    company = company.toLowerCase();
    if(partners.length==0){
      if(type=="m"){
        switch(company){
          case 'others':
            return "assets/images/carriers/medium/other.png"
          case 'amazon':
            return "assets/images/carriers/medium/amazon.png"
          case 'purolator':
            return "assets/images/carriers/medium/purolator.png"
          case 'puralator':
            return "assets/images/carriers/medium/purolator.png"
          case 'ups':
            return "assets/images/carriers/medium/ups.png"
          case 'dhl':
            return "assets/images/carriers/medium/dhl.png"
          case 'canpar':
            return "assets/images/carriers/medium/canpar.png"
          case 'canadapost':
            return "assets/images/carriers/medium/cp.png"
          case 'fedex':
            return "assets/images/carriers/medium/fedex.png"
          case 'intelcom':
              return "assets/images/carriers/medium/intelcom.png"
          case 'dragonfly':
              return "assets/images/carriers/medium/dragonfly.png"
          default:
            return "assets/images/carriers/medium/other.png"
          
    
        }
      }else{
        switch(company){
          case "canadapost":
            return "assets/images/carriers/small/canada-post.jpg";
          case "canpar":
            return "assets/images/carriers/small/canpar.jpg";
          case "amazon":
            return "assets/images/carriers/small/amazon.jpg";
          case "dhl":
            return "assets/images/carriers/small/dhl.jpg";
          case "fedex":
            return "assets/images/carriers/small/fedex.jpg";
          case "intelcom":
            return "assets/images/carriers/small/intelcom.jpg";
          case "puralator":
            return "assets/images/carriers/small/purolator.jpg";
          case "purolator":
            return "assets/images/carriers/small/purolator.jpg";
          case "ups":
            return "assets/images/carriers/small/ups.jpg";
          case "grocery":
            return "assets/images/carriers/small/groceries.jpg";
          case "food":
            return "assets/images/carriers/small/food-delivery.jpg";
          case "personal":
            return "assets/images/carriers/small/personal-delivery.jpg";
          case "jukebox":
            return "assets/images/carriers/small/jukebox.jpg";
          case "jukebox-return":
            return "assets/images/carriers/small/jukebox-return.jpg";
          case "school-exchange":
            return "assets/images/carriers/small/school-exchange.jpg";
          case "school-exchange-return":
            return "assets/images/carriers/small/school-exchange-return.jpg";
          case "dragonfly":
            return "assets/images/carriers/small/dragonfly.jpg";
          default:
            return "assets/images/carriers/small/other.jpg";
          
        }
      }

    }else{
      for(let partner of partners){
        if(company == partner.c.toLowerCase()){
          return environment.rc_url+partner.c_img;
        }
      }
      return "assets/images/carriers/small/other.jpg";
          
    }
    

  }

}
