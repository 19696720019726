import { CoreMenu } from '@core/types'
// Role: 
// System admin --> 10
// Building admin --> 1
// Advertisement admin --> 6
// Parcel admin --> 5
// Business partner indicator --> -2

export const menu: CoreMenu[] = [
  { 
    id: 'general',
    type: 'section',
    title: 'GENERAL',
    icon:'package',
    children:[
      // {id: 'overview',
      // title: 'Dashboards',
      // translate: '',
      // type: 'item',
      // icon: 'home',
      // url: 'dashboard',
      // exactMatch: true,
      // role: ['1', '5', '10']
      // },
      {
        id: 'dashboard_view',
        type: 'collapsible',
        title: 'Dashboard',
        translate: '',
        icon: 'home',
        role: ['1', '10', '5', '-2', '16'],
        children:[
          {
            id: 'manager_view',
            title: 'Manager View',
            icon: 'circle',
            role: ['1', '10', '5', '6', '-2', '16'],
            url: 'dashboard',
            type: 'item'
          },
          {
            id: 'admin_view',
            title: 'Admin View',
            icon: 'circle',
            type: 'item',
            role: ['10', '5'],
            url: 'admin-view',
            // badge: {
            //   title: '1',
            //   classes: 'badge-light-danger badge-pill round'
            // }
          },
          {
            id: 'admin2_view',
            title: 'Statistic View',
            icon: 'circle',
            type: 'item',
            role: ['10', '5'],
            url: 'statistic-view',
            // badge: {
            //   title: '1',
            //   classes: 'badge-light-danger badge-pill round'
            // }
          }
        ]
      },
      {
        id: 'orders',
        title: 'Orders',
        translate: '',
        type: 'item',
        icon: 'grid',
        url: 'orders',
        exactMatch: false,
        //set role to 10 for debug purpose
        role: ['10', '14'],

      },
      {
        id: 'parcels',
        title: 'Pending / Collected',
        translate: '',
        type: 'item',
        icon: 'package',
        url: 'parcels/',
        exactMatch: false,
        role: ['1', '5', '10', '11', '-2', '12', '14', '16'],
      },
      {
        id: 'members',
        title: 'Members',
        translate: '',
        type: 'item',
        icon: 'users',
        url: 'members',
        role: ['10']
      }, 
      // {
      //   id: 'display',
      //   type: 'collapsible',
      //   title: 'Display',
      //   translate: '',
      //   icon: 'airplay',
      //   children:[
      //     {
      //       id: 'notice',
      //       title: 'Notice',
      //       icon: 'circle',
      //       role: ['1', '10'],
      //       url: 'display/notice',
      //       type: 'item'
      //     },
      //     {
      //       id: 'advertisement',
      //       title: 'Advertisement',
      //       icon: 'circle',
      //       type: 'item',
      //       role: ['6', '10'],
      //       url: 'display/advertisement'
      //     },
      //   ]
      // },
      {
        id: 'settings',
        title: 'Locker Settings',
        translate: '',
        type: 'item',
        icon: 'settings',
        url: 'settings',
        exactMatch: false,
        role: ['1','10','11', '-2', '12', '14', '16'],
        // badge: {
        //   title: 'New',
        //   classes: 'badge-light-success badge-pill round'
        // }
      },
      {
        id: 'blueKey',
        title: 'BlueKey',
        translate: '',
        type: 'item',
        icon: 'key',
        url: 'bluekey',
        exactMatch: false,
        role: ['10'],
        // badge: {
        //   title: 'New',
        //   classes: 'badge-light-success badge-pill round'
        // }
      },
      {
        id: 'compartments',
        title: 'Compartments',
        translate: '',
        type: 'item',
        icon: 'grid',
        url: 'compartments',
        exactMatch: false,
        role: ['11'],
        // badge: {
        //   title: 'New',
        //   classes: 'badge-light-success badge-pill round'
        // }
      },

      // {
      //   id: 'pending',
      //   title: 'Pending',
      //   translate: '',
      //   type: 'item',
      //   icon: 'settings',
      //   url: 'new-setting',
      //   exactMatch: false,
      //   role: ['10'],
      // },
      {
        id: 'community',
        title: 'Community',
        translate: '',
        type: 'collapsible',
        icon: 'clipboard',
        // url: 'community',
        exactMatch: false,
        role: ['10'],
        children:[
          {
            id: 'community_msg',
            title: 'Community Msgs',
            icon: 'circle',
            url: 'community',
            type: 'item',
            role: ['-2', '10']
          },
          {
            id: 'system_msg',
            title: 'Community System Msgs',
            icon: 'circle',
            url: 'system-msg',
            type: 'item',
            role: ['10']
          },

        ]
      },
      {
        id: 'Manual',
        title: 'Manual',
        translate: '',
        type: 'item',
        icon: 'book-open',
        url: 'help-support/manual',
        exactMatch: false,
        role: ['1','10','11', '-2']
      },
      {
        id: 'release-notes',
        title: 'Product Update',
        translate: '',
        type: 'item',
        icon: 'clipboard',
        url: 'release-notes',
        exactMatch: false,
        role: ['1','10','11', '-2'],
        badge: {
          title: 'New',
          classes: 'badge-light-success badge-pill round'
        }
      },
      // {
      //   id: 'subscription',
      //   title: 'Subscription',
      //   translate: '',
      //   type: 'item',
      //   icon: 'star',
      //   url: 'subscription',
      //   exactMatch: false,
      //   role: ['10', '1'],
      //   // badge: {
      //   //   title: 'New',
      //   //   classes: 'badge-light-success badge-pill round'
      //   // }
      // }
    ]
  },{
    id: 'tools',
    type: 'section',
    title: 'TOOLS',
    icon:'tools',
    role:['10', '-2'],
    children:[
      {
        id: 'advertisement',
        title: 'Advertisement',
        type: 'collapsible',
        icon: 'cast',
        role: ['6', '10'],
        children: [
          {
            id: 'advertisement0',
            title: 'Ad Agents',
            icon: 'circle',
            type: 'item',
            role: ['6', '10'],
            url: 'advertise-setting'
          },
          {
            id: 'ad-test',
            title: 'Ad Analytics',
            icon: 'circle',
            type: 'item',
            role: ['6', '10'],
            url: 'ad-test'
          },
          {
            id: '',
            title: 'Ad Groups',
            icon: 'circle',
            type: 'item',
            role: ['6', '10'],
            url: 'business'
          },
          {
            id: 'redirect_setting',
            title: 'Redirect Setting',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: 'redirect-setting',
            role: ['10']
          }
        ]
        
      },
      {
        id: 'machine',
        title: 'Machines',
        translate: '',
        type: 'collapsible',
        icon: 'grid',
        role: ['10', '-2'],
        children:[
          {
            id: 'lockers',
            title: 'Machine',
            icon: 'circle',
            url: 'machines/lockers',
            type: 'item',
            role: ['-2', '10']
          },
          {
            id: 'models',
            title: 'Models',
            icon: 'circle',
            url: 'machines/models',
            type: 'item',
            role: ['10']
          },

        ]
      },
      {
        id: 'tokens',
        title: 'Tokens',
        translate: '',
        type: 'item',
        icon: 'link',
        url: 'tokens',
        role: ['10']
      },
      {
        id: 'authority',
        title: 'Authority',
        translate: '',
        type: 'item',
        icon: 'eye',
        url: 'authority',
        role: ['10']
      },
      {
        id: 'sms',
        title: 'SMS',
        translate: '',
        type: 'collapsible',
        icon: 'edit',
        role: ['10'],
        children:[
          {
            id: 'number_list',
            title: 'Number List',
            icon: 'circle',
            url: 'sms/list',
            type: 'item'
          },
          {
            id: 'message_modal',
            title: 'SMS Template',
            icon: 'circle',
            url: 'sms/model',
            type: 'item'
          },
          {
            id: 'notification_modal',
            title: 'Notification Template',
            icon: 'circle',
            url: 'sms/notification',
            type: 'item'
          },
          {
            id: 'sms-dashboard',
            title: 'SMS Dashboard',
            icon: 'circle',
            url: 'sms/sms-dashboard',
            type: 'item'
          },
        ]
      },
      {
        id: 'logs',
        title: 'Logs',
        translate: '',
        type: 'collapsible',
        icon: 'file-text',
        role:['10'] ,
        children:[
          {
            id: 'machine_logs',
            title: 'Machine Logs',
            icon: 'circle',
            url: 'logs/machine',
            type: 'item'
          },
          {
            id: 'member_logs',
            title: 'Member Logs',
            icon: 'circle',
            url: 'logs/operator',
            type: 'item'
          },
          {
            id: 'crash_logs',
            title: 'Crash Logs',
            icon: 'circle',
            url: 'logs/crash',
            type: 'item'
          },
          // {
          //   id: 'sms_logs',
          //   title: 'Sms Logs',
          //   icon: 'circle',
          //   url: 'logs/sms',
          //   type: 'item'
          // },
          // {
          //   id: 'sms_failed',
          //   title: 'Sms Failed Logs',
          //   icon: 'circle',
          //   url: 'logs/sms-failed',
          //   type: 'item'
          // }
        ]
      },
      {id: 'service',
      title: 'Service',
      translate: '',
      type: 'item',
      icon: 'server',
      url: 'web-service',
      role: ['10']
      },
      {id: 'customer_service',
      title: 'Support',
      translate: '',
      type: 'item',
      icon: 'message-square',
      url: 'customer-service',
      role: ['10']
      },
      {id: 'black_list',
      title: 'Black List',
      translate: '',
      type: 'item',
      icon: 'message-square',
      url: 'users-locked',
      role: ['10']
      },
      {id: 'email',
      title: 'Email',
      translate: '',
      type: 'item',
      icon: 'mail',
      url: 'email',
      role: ['10']
      },
      {
        id: "unit-file-processor",
        title: "Unit File Processor",
        translate: "",
        type: "item",
        icon: "upload-cloud",
        url: "unit-file-processor",
        role: ["10"],
      }
    ]
  },{
    id: 'Bluemart',
    type: 'section',
    title: 'BLUEMART',
    icon:'shopping-cart',
    role:['10'],
    children:[
      {
        id: 'Group_Buy_Schedule',
        title: 'Group-Buy Setup',
        translate: '',
        type: 'item',
        icon: 'shopping-cart',
        url: 'group-buy-schedule',
        role: ['10']
      },   
      {
        id: 'Reward_System',
        title: 'Reward System',
        translate: '',
        type: 'item',
        icon: 'gift',
        url: 'reward-system',
        role: ['10']
      },
      {
        id: 'Premium_Member',
        title: 'Premium Member',
        translate: '',
        type: 'item',
        icon: 'gift',
        url: 'premium-member',
        role: ['10']
      },

    ]
  },{
    id: 'blueexpress',
    type: 'section',
    title: 'BLUEEXPRESS',
    icon:'box',
    role:['10'],
    children:[
      {
        id: 'orders',
        title: 'Orders',
        translate: '',
        type: 'item',
        icon: 'box',
        url: 'blueexpress',
        exactMatch: false,
        role: ['10']
      },
    ]
  }
  
  
 
]
