import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';


import { AgmCoreModule } from '@agm/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ChargeModeComponent } from './main/charge-mode/charge-mode.component';
import {ScreenTrackingService, UserTrackingService}
from '@angular/fire/analytics';
import {AngularFireAnalyticsModule} from "@angular/fire/compat/analytics";
import {AngularFireModule} from "@angular/fire/compat";
import { environment } from 'environments/environment';

const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'parcels',
    loadChildren: () => import('./main/parcels/parcels.module').then(m => m.ParcelsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./main/locker-settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'members',
    loadChildren: () => import('./main/members/members.module').then(m => m.MembersModule),
  },

  {
    path: 'profile',
    loadChildren: () => import('./main/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'display',
    loadChildren: () => import('./main/display/display.module').then(m => m.DisplayModule),
  },
  {
    path: 'advertise-setting',
    loadChildren: () => import('./main/adsetting/adsetting.module').then(m => m.ADsettingModule),
  },
  {
    path: 'machines',
    loadChildren: () => import('./main/machines/machines.module').then(m => m.MachinesModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/home.module').then(m => m.HomeModule),
  },
  {
    path: 'admin-view',
    loadChildren: () => import('./main/dashboard/admin-view/admin-view.module').then(m => m.AdminViewModule),
  },
  {
    path: 'statistic-view',
    loadChildren: () => import('./main/dashboard/statistic/statistic.module').then(m => m.StatisticModule),
  },
  {
    path: 'logs',
    loadChildren: () => import('./main/logs/logs.module').then(m => m.LogsModule),
  },
  {
    path: 'web-service',
    loadChildren: () => import('./main/web-service/web-service.module').then(m => m.WebServiceModule),
  },
  {
    path: 'sms',
    loadChildren: () => import('./main/sms/sms.module').then(m => m.SmsModule),
  },
  {
    path: 'customer-service',
    loadChildren: () => import('./main/customer-service/customer-service.module').then(m => m.CustomerServiceModule),
  },
  {
    path: 'ad-test',
    loadChildren: () => import('./main/adsetting/ad-test/ad-test.module').then(m => m.AdTestModule),
  },
  {
    path: 'business',
    loadChildren: () => import('./main/business/business.module').then(m => m.BusinessModule),
  },
  {
    path: 'new-setting',
    loadChildren: () => import('./main/locker-settings/new-setting/new-setting.module').then(m => m.NewSettingModule),
  },
  {
    path: 'security-camera-view',
    loadChildren: () => import('./main/security-camera-view/security-camera-view.module').then(m => m.SecurityCameraViewModule),
  },
  {
    path: 'community',
    loadChildren: () => import('./main/community/community.module').then(m => m.CommunityModule),
  },
  {
    path: 'tokens',
    loadChildren: () => import('./main/tokens/tokens.module').then(m => m.TokensModule),
  },
  {  
    path: 'users-locked',
    loadChildren: () => import('./main/users-locked/users-locked.module').then(m => m.UsersLockedModule),
  },  
  {
    path: 'group-buy-schedule',
    loadChildren: () => import('./main/group-buy/group-buy.module').then(m => m.GroupBuyModule),
  },
  {  
    path: 'authority',
    loadChildren: () => import('./main/authority/authority.module').then(m => m.AuthorityModule),
  },
  {
    path: 'redirect-setting',
    loadChildren: () => import('./main/redirect-setting/redirect-setting.module').then(m => m.RedirectSettingModule),
  },
  {
    path: 'reward-system',
    loadChildren: () => import('./main/reward-system/reward-system.module').then(m => m.RewardSystemModule),
  },
  {
    path: 'compartments',
    loadChildren: () => import('./main/compartments/compartments.module').then(m => m.CompartmentsModule),
  },
  {
    path: 'shopping-mall',
    loadChildren: () => import('./main/locker-settings/shopping-mall/shopping-mall.module').then(m => m.ShoppingMallModule),
  },
  {
    path: 'header-plus-screen',
    loadChildren: () => import('./main/locker-settings/header-plus-screen/header-plus-screen.module').then(m => m.HeaderPlusScreenModule),
  },
  {
    path: 'subscription',
    loadChildren: () => import('./main/charge-mode/charge-mode.module').then(m => m.SubscriptionModule),
  },
  {
    path: 'system-msg',
    loadChildren: () => import('./main/community/system-msg/system-msg.module').then(m => m.SystemMsgModule),
  },
  {
    path: 'help-support',
    loadChildren: () => import('./main/help-support/help-support.module').then(m => m.HelpSupportModule)
  },
  {
    path: 'email',
    loadChildren: () => import('./main/email/email.module').then(m => m.EmailModule)
  },
  {
    path: 'dev-tools',
    loadChildren: () => import('./main/dev-tools/dev-tools.module').then(m => m.DevToolsModule)
  },
  {
    path: 'bluekey',
    loadChildren: () => import('./main/bluekey/bluekey.module').then(m => m.BlueKeyModule)
  },
  {
    path: 'premium-member',
    loadChildren: () => import('./main/premium-member/premium-member.module').then(m => m.PremiumMemberModule)
  },
  {
    path: 'blueexpress',
    loadChildren: () => import('./main/blueexpress/blueexpress.module').then(m => m.BlueExpressModule)

  },
  {
    path: 'bike',
    loadChildren: () => import('./main/bike/bike.module').then(m => m.BikeModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./main/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'release-notes',
    loadChildren: () => import('./main/release-notes/release-notes.module').then( m => m.ReleaseNotesModule)
  },
  {
    path: 'minibox-locker-list',
    loadChildren: () => import('./main/minibox-locker-list/minibox-locker-list.module').then( m => m.MiniboxLockerListModule)
  },
  {
    path: 'amenity',
    loadChildren: () => import('./main/amenity/amenity.module').then(m => m.AmenityModule)
  },
  {
    path: '',
    redirectTo: '/pages/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  },


];

@NgModule({
  declarations: [AppComponent, ChargeModeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    CommonModule,
    SwiperModule,
    //Ng-select Modules
    NgSelectModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDwS9p0sMz_hqbqKDgQMPukLaBTZSp-l1o',
      libraries: ['places']
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy, }, Title, ScreenTrackingService,
    UserTrackingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
