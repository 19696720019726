import { CoreMenu } from '@core/types'

export const amenityMenu: CoreMenu[] = [
    { 
        id: 'amenity',
        type: 'section',
        title: 'OPERATION',
        icon:'',
        children:[
          {
            id: 'amenity-machine',
            type: 'item',
            title: 'Machine',
            translate: '',
            icon: 'codesandbox',
            role: ['1', '10'],
            url: 'amenity/machine',
          },
          {
            id: 'amenity-setting',
            title: 'Settings',
            translate: '',
            type: 'item',
            icon: 'headphones',
            url: 'amenity/setting',
            exactMatch: false,
            role: ['1', '10']
          },
          {
            id: 'Bluetags',
            title: 'BlueTags',
            translate: '',
            type: 'item',
            icon: 'headphones',
            url: 'amenity/tags',
            exactMatch: false,
            role: ['1', '10']
          },
          {
            id: 'deposits',
            title: 'Deposits',
            translate: '',
            type: 'item',
            icon: 'headphones',
            url: 'amenity/deposits',
            exactMatch: false,
            role: ['1', '10']
          }
        ]
    }

]