export enum Role {
  SysAdmin = '10',
  BdAdmin = '1',
  ParcelAdmin = '5',
  ADAdmin = '6',
  MetroAdmin = '11', // shopping mall mode
  Jukebox = '12', // Jukebox mode,
  EMCO = '14',
  VIU = '16', // VIU mode
  MiniBox = '13'
}
