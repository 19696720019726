import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimeFormatPipe } from '@core/pipes/time-format.pipe';
import { AuthenticationService } from 'app/auth/service';
import { BuildingsService } from 'app/service/buildings.service';
import { DataManageService } from 'app/service/data-manage.service';
import { resolve } from 'dns';

import { BehaviorSubject, Subscriber } from 'rxjs';
import * as moment from 'moment';
import { TimestampService } from 'app/service/timestamp.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;
  public requestList = [];
  public onRequestList: BehaviorSubject<any>;

  public abnormalList = [];
  public onAbnormalList: BehaviorSubject<any>;

  public timedoutList = [];

  public supportRequests: BehaviorSubject<any>;
  public unattendedDelivery: BehaviorSubject<any>;

  public pendingPost = [];
  public onPendingPost: BehaviorSubject<any>;
  public onTimedoutParcelList: BehaviorSubject<any>;

  public isSubscribed: any;
  public userData: any;

  public refreshTime: any;
  public notificationData: any;
  isSkip = false;
  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
    private authService: AuthenticationService,
    private data_manager: DataManageService,
    private _buildings: BuildingsService,
    private timestampService: TimestampService) {
      this.notificationData = JSON.parse(localStorage.getItem('notificationData'));
      if(!this.notificationData){
          this.onApiDataChange = new BehaviorSubject([]);
          this.onRequestList = new BehaviorSubject([]);
          this.onAbnormalList = new BehaviorSubject([]);
          this.onTimedoutParcelList = new BehaviorSubject([]);
          this.supportRequests = new BehaviorSubject([]);
          this.unattendedDelivery = new BehaviorSubject([]);
          this.onPendingPost = new BehaviorSubject([]);
          this.notificationData = {
            apiData: [],
            requestList: [],
            abnormalList: [],
            timedoutList: [],
            supportRequests: [],
            unattendedDelivery: [],
            pendingPost: []
          }
          localStorage.setItem('notificationData', JSON.stringify({
            apiData: [],
            requestList: [],
            abnormalList: [],
            timedoutList: [],
            supportRequests: [],
            unattendedDelivery: [],
            pendingPost: []
          }));
      }else{
        // console.log('hi')
        this.onApiDataChange = new BehaviorSubject(this.notificationData.apiData);
        this.onRequestList = new BehaviorSubject(this.notificationData.requestList);
        this.onAbnormalList = new BehaviorSubject(this.notificationData.abnormalList);
        this.onTimedoutParcelList = new BehaviorSubject(this.notificationData.timedoutList);
        this.supportRequests = new BehaviorSubject(this.notificationData.supportRequests);
        this.unattendedDelivery = new BehaviorSubject(this.notificationData.unattendedDelivery);
        this.onPendingPost = new BehaviorSubject(this.notificationData.pendingPost);
      }


      //get las request time
      let temp_time:any = JSON.parse(localStorage.getItem("lastRequest"));
      // console.log(temp_time)
      if(temp_time){
        this.refreshTime = new Date(Number(temp_time.time));
      }else{
        this.isSkip = true;
        this.refreshTime = this.timestampService.getTime();
      }

      let current_time = new Date();
      //if the last request time is more than 5 minutes, renew the timestamp
      // if(current_time.getTime() - this.refreshTime.getTime() > 5*60*1000){
      //   if(this.userData){
      //     console.log("reached")
      //     this.getNotifications();
      //   }
      //   this.timestampService.renewTimestamp();

      // }


      // console.log("navbar")
      if(!this.isSubscribed){
        this.isSubscribed = this.authService.currentUser.subscribe(data => {
          // console.log(data)
          if(!data){
            return;
          }else{
            this.userData = data;
            // console.log("has user data")
            if(this.isSkip){
              this.getNotifications();
              this.isSkip = false;
              return;
            }
            if(current_time.getTime() - this.refreshTime.getTime() >= 5*60*1000){
              if(this.userData){
                // console.log("reached")
                this.getNotifications();
              }
              this.timestampService.getTime();
      
            }
          }

        })
      }
      // this.isSubscribed = this.authService.currentUser.subscribe(data => {
      //   // console.log(data)
      //   if(data){

      //     this.getNotificationsByBuid();
      //     this.getCustomerRequest();
      //     this.getAbnormalList();
      //     this.getPendingPostList();
      //   }
      // })
      
  }
  getNotifications(){
    // console.log("get notifications")
    this.getNotificationsByBuid();
    this.getCustomerRequest();
    this.getAbnormalList();
    this.getPendingPostList();
  }

  /**
   * Get Notifications Data
   */
  // getNotificationsData(): Promise<any[]> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient.get('api/notifications-data').subscribe((response: any) => {
  //       this.apiData = response;
  //       this.onApiDataChange.next(this.apiData);
  //       resolve(this.apiData);
  //     }, reject);
  //   });
  // }

  getNotificationsByBuid(){
    this._buildings.buildings.subscribe((data:any) => {
      // console.log(data)
      if(data){

        this.requestList = []
        this.abnormalList = []
        if(this.authService.currentUserValue.level=='10'){
          // console.log('reached admin level')
          this.getUnitLists()
          this.getAbnormalParcelsList()
        }else{
          // console.log('reached user level')
          if(data.length >0){
            for (let item of data){
              this.getUnitLists(item.buid, item.bname)
              this.getAbnormalParcelsList(item.buid, item.bname)
              this.getTimedoutParcels(item.buid)
            }
          }
        }
      }
    });
  }
  
  // get 当前locker的unit的申请个数，给BM和admin管理员用
  getUnitLists(buid?, bname?){
    // let url_parameters:any = {};
    if(!buid){
      buid = '';
    }
    var url_parameters = {'t':this.authService.currentUserValue.token,'mid':buid,'s':'0','c':'100','sts':'0'};
    // console.log(url_parameters)
    var encoded_url = this.data_manager.encryptMsg(url_parameters)
    var temp_input = {act: 50074, m:1, par:encoded_url}

      
    this.data_manager.http_request(temp_input).subscribe(
      {
        next: ret=>{
          var re = JSON.parse(this.data_manager.decryptMsg(ret));
          // console.log(re)
          var item = {
            bname: bname,
            buid: buid,
            count: re.length
          }
          
          this.requestList.push(item)
          
          this.onRequestList.next(this.requestList);
          // console.log(this.requestList)
          // console.log(this.notificationData)
          this.notificationData['requestList'] = this.requestList;
          localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
        },
        error: err=>{
          console.log(err)
        }
      }
      

    );
    
   
  }

  getAbnormalParcelsList(buid?,bname?){
    // this.abnormalList=[];
    if(!buid){
      buid = '';
    }
    var url_parameters = {'t':this.authService.currentUserValue.token,'mc':buid};
    var encoded_url = this.data_manager.encryptMsg(url_parameters);
    var temp_input = {act: 8034, m:1, par:encoded_url};
    this.data_manager.http_request(temp_input).subscribe(data=>{
      var temp_data = JSON.parse(this.data_manager.decryptMsg(data));
      
      for(let parcel of temp_data){
        parcel.pkg = JSON.parse(this.data_manager.decryptMsg(parcel.pkg));
      }
      // console.log(temp_data)
      var item = {
        bname: bname,
        buid: buid,
        count: temp_data.length
      }
      // console.log(this.abnormalList)
      this.abnormalList.push(item)
      this.onAbnormalList.next(this.abnormalList)
      this.notificationData['abnormalList'] = this.abnormalList;
      localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
      // this.getAbnormDetail(temp_data);
    }); 
  }

  getCustomerRequest(){
    var url_parameters = {'t':this.authService.currentUserValue.token,'s':'0'};
    var encoded_url = this.data_manager.encryptMsg(url_parameters)
    var temp_input = {act: 50072, m:1, par:encoded_url};
    this.data_manager.http_request(temp_input).subscribe({
      next: ret=>{
        var vet = JSON.parse(this.data_manager.decryptMsg(ret));
        this.supportRequests.next(vet)
        this.notificationData['supportRequests'] = vet;
        localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
        // console.log(this.data_manager.decryptMsg(ret));
      },
      error: err=>{
        console.log(err);
      }
    });
    
  }

  getAbnormalList() {
    // console.log("cube installed")
    var pram = { 't': this.authService.currentUserValue.token };
    var encoded_url = this.data_manager.encryptMsg(pram);
    var temp_input = { act: 50080, m: 1, par: encoded_url };
    this.data_manager.http_request(temp_input)
      .subscribe(
        {
          next: ret => {
           var parcels_ab = JSON.parse(this.data_manager.decryptMsg(ret));
           if(parcels_ab.length > 0){

             for(let parcel of parcels_ab){
               // decrypt parcel pkg information
              parcel.pkg = JSON.parse(this.data_manager.decryptMsg(parcel.pkg));
               // add building name in the parcel
             }
            }
            this.unattendedDelivery.next(parcels_ab)
            this.notificationData['unattendedDelivery'] = parcels_ab;
            localStorage.setItem('notificationData', JSON.stringify(this.notificationData));


          },
          error: err => {

          }
        }
      );
  }

  getPendingPostList(){
    var pram = {
      status: 0
    }
    this.data_manager.communityRequest('/bulletinlistbystatus', 'post', pram).subscribe({
      next: ret=>{
        ret = JSON.parse(ret);
        // console.log(ret.result);
        this.onPendingPost.next(ret.result);
        this.notificationData['pendingPost'] = ret.result;
        localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
      },
      error: err=>{
        console.log(err);
      }
    });
    
  }


  getTimedoutParcels(buid){
    // let formatPipe = new TimeFormatPipe();
    // let temp_date2 = formatPipe.formatParcelDate(new Date());

    // let st:any = temp_date2.split(" ");
    // console.log(st)
    // let temp_date = st[0].split('-');
    // temp_date[2]='01';
    // st = temp_date.join('-');
    // console.log(temp_date2);
    var tomorrow = moment().add(1,'days').format("YYYY-MM-DD");
    // console.log(tomorrow)

    var pram = { 't': this.authService.currentUserValue.token, 'm': buid, 'b':'2000-01-01', 'e':tomorrow+" 23:59:59", 's':'0'};
    console.log("timeout pram is "+JSON.stringify(pram));
    var encoded_url = this.data_manager.encryptMsg(pram);
    var temp_input = { act: 50114, m: 1, par: encoded_url };
    this.data_manager.http_request(temp_input).subscribe({
      next: ret=>{
        let parcels = JSON.parse(this.data_manager.decryptMsg(ret));
        console.log(parcels);
        for(let parcel of parcels){
          parcel.pkg = JSON.parse(this.data_manager.decryptMsg(parcel.pkg));
        }
        if(parcels.length>0){
          var building_parcel = {
            addr: parcels[0].pkg.a.split("|")[0],
            uuid: parcels[0].muuid,
            count: parcels.length
          }
          this.timedoutList.push(building_parcel);
          this.onTimedoutParcelList.next(this.timedoutList);
          this.notificationData['timedoutList'] = this.timedoutList;
          localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
        }

        // console.log(JSON.stringify(parcels));
      }
    })

  }
}
