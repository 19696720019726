<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img height="32" src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" onerror="this.onerror=null;this.src='assets/images/logo/logo.svg'" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>

    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
        <i
          [ngClass]="isCollapsed === true ? 'icon-chevrons-left' : 'icon-chevrons-right'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon"
        ></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 toggle-icon"></i>
      </a>
    </li>
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main" layout="vertical">
    <li
      core-menu-vertical-section
      class="navigation-header main-menu-section"
    >Management Portal</li>
    <li [ngClass]="{'active':currentMenu=='main'}" *ngIf="(user.level=='10'&&!user.bPartner)||authLevel.indexOf('1')>-1">
      <!-- <li [ngClass]="{'active':currentMenu=='main'}"> -->

      <a (click)="setCurrentMenu('main')">
        <i data-feather="box"></i>
        <span class="menu-title">BlueBox</span>
      </a>
    </li>
    <li [ngClass]="{'active':currentMenu=='bike'}" *ngIf="(user.level=='10'&&!user.bPartner)||authLevel.indexOf('3')>-1">
    <!-- <li [ngClass]="{'active':currentMenu=='bike'}" *ngIf="(user.level=='10'&&!user.bPartner)"> -->

      <a (click)="setCurrentMenu('bike')">
        <i data-feather="bold"></i>
        <span class="menu-title">Bike Locker</span>
      </a>
    </li>
    <!-- MiniBox -->
    <li [ngClass]="{'active':currentMenu=='miniBox'}" *ngIf="(user.level=='10'&&!user.bPartner)">  
        <a (click)="setCurrentMenu('miniBox')">
          <i data-feather="bold"></i>
          <span class="menu-title">MiniBox</span>
        </a>
    </li>
    <li [ngClass]="{'active':currentMenu=='amenity'}" *ngIf="(user.level=='10'&&!user.bPartner)">  
      <a (click)="setCurrentMenu('amenity')">
        <i data-feather="bold"></i>
        <span class="menu-title">Amenity</span>
      </a>
  </li>
  </ul>
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div>
<!--/ Main menu -->
