import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { DataManageService } from 'app/service/data-manage.service';
import { BuildingsService } from 'app/service/buildings.service';
import { PartnerService } from 'app/service/partner.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, 
    private _toastrService: ToastrService, 
    private dataService: DataManageService,
    private partnerService: PartnerService,
    private buildings: BuildingsService) {
      try{
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      }
      catch(e){
        // console.log(e);
        //try decrypting local storage data
        var temp = localStorage.getItem('currentUser');
        var decrypted = this.dataService.decryptMsg(temp);
        // console.log(decrypted)
        var userObj = JSON.parse(decrypted);
        userObj = JSON.parse(userObj);
        this.currentUserSubject = new BehaviorSubject<User>(userObj);
        // console.log(typeof(this.currentUserSubject.value));
      }
   
    this.currentUser = this.currentUserSubject.asObservable();
    // console.log(this.currentUserSubject.value);
    // console.log(typeof(this.currentUserSubject.value));
    try{
      if(!this.currentUserSubject.value){
      
      }else if(!('partner' in this.currentUserSubject.value)){
        // console.log(this.currentUserSubject.value.partner);
        // console.log("I am here noooooo");
        this.logout();
      }
    }catch(e){
      console.log(e);
      // console.log('asdlfjka;dsfkl;asdkfjl');
      this.logout();
    }
    

  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is system admin
   */
  get isSysAdmin() {
    if(this.currentUser){
      return this.currentUserSubject.value.level === Role.SysAdmin;
    }else{
      return false;
    }
  }

  /**
   *  Confirms if user is building admin
   */
  get isBdAdmin() {
    if(this.currentUser){
      return this.currentUserSubject.value.level === Role.BdAdmin;
    }else{
      return false;
    }
  }

  get isADAdmin() {
    if(this.currentUser){
      return this.currentUserSubject.value.level === Role.ADAdmin;
    }else{
      return false;
    }
  }

  get isPclAdmin(){
    if(this.currentUser){
      return this.currentUserSubject.value.level === Role.ParcelAdmin;
    }else{
      return false;
    }
  }

  get isPartner(){
    if(this.currentUser){
      return this.currentUserSubject.value.partner=="YK-Gold";
    }else{
      return false
    }
  }

  get isBPartner(){
    if(this.currentUser){
      return this.currentUserSubject.value.level == Role.SysAdmin && this.currentUserSubject.value.partner!="Bluebits";
    }else{
      return false
    }
  }

  get isMetroAdmin(){
    if(this.currentUser){
      return this.currentUserSubject.value.level === Role.MetroAdmin;
    }else{
      return false
    }
  }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */
  login(username: string, password: string) {
    var url_parameters = {'u':username,'p':password};
    var encoded_url = this.dataService.encryptMsg(url_parameters)
    var temp_input = {act: 8002, m:1, par:encoded_url}
    //get date using format yyyy-mm-dd hr:min

    return this.dataService.http_request(temp_input)
    .pipe(
      map(ret =>{
        var temp = this.dataService.decryptMsg(ret);
        console.log(temp);
        var user= Object.assign(new User(), JSON.parse(temp));
        if (user && user.ret>0) {
          // console.log(JSON.stringify(user))
          if(user.partner == 'YK-Gold'){
            this.getPartnerInfo(user.partner, user.token);
          }
          if(user.level == 1){
            this.getMachineModeFrom8012(user, user.token);
          } 
          if(user.level == 10 && user.partner != 'BB'){
            user.bPartner = true;
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', this.dataService.encryptMsg(JSON.stringify(user)));

          // Display welcome toast!
          setTimeout(() => {
            this._toastrService.success(
              'You have successfully logged in. Enjoy! 🎉',
              '👋 Welcome!', 
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }, 2500);
          this.currentUserSubject.next(user);
        }
        return user;
      })

    );
    
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentBuilding');
    localStorage.removeItem('currentParcels');
    localStorage.removeItem('selectedParcel');
    localStorage.removeItem('currentPartner');
    localStorage.removeItem('bm-currentBuilding');
    localStorage.removeItem('notificationData');
    // notify
    this.currentUserSubject.next(null);
    this.buildings.currentBuildingSubject.next(null);
  }

  getPartnerInfo(partner, token){
    var url_parameters = {'t':token, 'p': 'YK-Gold' };
    var encoded_url = this.dataService.encryptMsg(url_parameters)
    var temp_input = {act: 50091, m:1, par:encoded_url}
    this.dataService.http_request(temp_input)
    .subscribe(
      {
        next: ret=>{
          var v_ret = JSON.parse(this.dataService.decryptMsg(ret));
          // console.log(v_ret)
          this.partnerService.setPartner(v_ret);
          
        },
        error: err=>{
          console.log(err)
        }
      }

    );
  }

  getMachineModeFrom8012(user, token){
    var url_parameters = {'t':token, 'm':0, 'n':1000 };
    var encoded_url = this.dataService.encryptMsg(url_parameters)
    var temp_input = {act: 8012, m:1, par:encoded_url}
    this.dataService.http_request(temp_input)
    .subscribe(
      {
        next: ret=>{
          var v_ret = JSON.parse(this.dataService.decryptMsg(ret));
          // console.log(v_ret)
          if(v_ret.some(item => item.mm == 6 || item.mm == 8)){
            user.level = '11'
          }else if(v_ret.some(item => item.mm == 12)){
            console.log("jukebox mode")
            user.level = '12'
          }else if(v_ret.some(item => item.mm == 14)){
            console.log("EMCO mode")
            user.level = '14'
          }else if(v_ret.some(item => item.mm == 16)){
            user.level = '16'
          }
          localStorage.setItem('currentUser', this.dataService.encryptMsg(JSON.stringify(user)));
          this.currentUserSubject.next(user);

        },
        error: err=>{
          console.log(err)
        }
      }

    );
  }
}
